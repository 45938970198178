import React from "react"
import Header from "../components/header"

const NotFound = () => {
  return (
    <div>
      <Header />
      <h1 className="mar textCenter">404 Error Page</h1>
    </div>
  )
}

export default NotFound
